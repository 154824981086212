<template lang="pug">
section(v-if="route.path !== '/account/plan/' && route.path !== '/'")
  .notification.is-warning.has-text-centered.has-text-weight-semibold(style="border-bottom: 6px solid #333;border-top: 6px solid #333;")
    .is-flex.is-justify-content-center.is-align-items-center
      Icon( name="hugeicons:square-lock-02" :size="`24px`" color="#333").mr-2
      | You are currently in trial mode. Please&nbsp;
      nuxt-link(to="/account/plan/") upgrade your account
      |  &nbsp;to unlock more features of the platform.
</template>
<script setup>
const route = useRoute();
import { useAccountStore } from "~/stores/account";
import { useTeamStore } from "~/stores/team";
const accountStore = useAccountStore();
const teamStore = useTeamStore();

await accountStore.billingDetails();
</script>
<style lang="scss"></style>
